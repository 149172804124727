import { PortableTextTypeComponent } from "@portabletext/react";
import {
  ValuePropositionItems,
  ValuePropositionList as ValuePropositionListTypes,
} from "./types";
import { SanityIcon } from "@/cms/icons/Icon";
import classNames from "classnames";

export const ValuePropositionList: PortableTextTypeComponent<
  ValuePropositionListTypes
> = ({ value: { items } }) => {
  if (!items) return null;
  return <ValuePropositionListItems items={items} />;
};

export const ValuePropositionListItems = ({
  items,
  className,
}: {
  className?: string;
  items: ValuePropositionItems;
}) => {
  return (
    <ul
      className={classNames(className, "gap-4 sm:flex")}
      data-testid="value-proposition-list"
    >
      {items?.map(({ valueProposition, icon }) => {
        if (!valueProposition || !icon) return null;
        return (
          <li
            key={valueProposition._id}
            className="grid grid-cols-[auto_1fr] items-center gap-2"
          >
            <SanityIcon data={{ ...icon }} size={24} />
            <span>{valueProposition.title}</span>
          </li>
        );
      })}
    </ul>
  );
};
